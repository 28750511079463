import React, { Component } from "react";
import { Link, injectIntl } from "gatsby-plugin-react-intl";
import { Helmet } from "react-helmet";
import "./styling/contact.css";
import Layout from "../components/layout/layout";
import { FormattedMessage } from "gatsby-plugin-react-intl";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayButton: false,
      permission: null,
      Subject: "",
      Body: "",
    };
  }

  //Scroll to top Functions
  scrollStep() {
    if (window.pageYOffset === 0) {
      clearInterval(this.state.intervalId);
    }
    window.scroll(0, window.pageYOffset - 50);
  }

  scrollToTop() {
    let intervalId = setInterval(this.scrollStep.bind(this), 17);
    this.setState({ intervalId: intervalId });
  }

  //mount and unmount functions
  componentDidMount() {
    window.addEventListener("scroll", this.calcScroll.bind(this, 350));
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this._calcScroll);
  }

  // Scroll-trigger function
  calcScroll(h1) {
    var _window = window;
    var heightDiff = parseInt(h1);
    var scrollPos = _window.scrollY;
    if (scrollPos > heightDiff) {
      this.setState({
        displayButton: true,
      });
    } else {
      this.setState({
        displayButton: false,
      });
    }
  }

  submit() {
    this.setState({
      permission: true,
    });
  }

  cancel() {
    this.setState({
      permission: false,
    });
  }

  setEmailParameters(event) {
    const { value, placeholder } = event.target;

    if (placeholder === "Subject") {
      this.setState({
        Subject: value,
      });
    } else {
      this.setState({
        Body: value,
      });
    }
  }

  render() {
    const path = this.props.location.pathname;
    const { permission, Body, Subject } = this.state;
    const href = `mailto:contact@narando.de?subject=${Subject}&body=${encodeURIComponent(
      Body
    )}`;

    const Main = (
      <Layout
        path={path}
        title={this.props.intl.formatMessage({ id: "contact.title" })}
      >
        <IFrame locale={this.props.intl.locale} />
      </Layout>
    );

    const emailOverlay = (
      <div className="background">
        <Helmet
          title={this.props.intl.formatMessage({ id: "contact.title" })}
        />
        <div className="box">
          <div className="emailForm">
            <h2>
              <FormattedMessage id="contact.emailHeading" />
            </h2>
            <FormattedMessage id="contact.emailSubject">
              {(placeholder) => (
                <input
                  className="emailInput"
                  onChange={(e) => this.setEmailParameters(e)}
                  placeholder={placeholder}
                  type="text"
                />
              )}
            </FormattedMessage>
            <FormattedMessage id="contact.emailText">
              {(placeholder) => (
                <textarea
                  className="emailText"
                  onChange={(e) => this.setEmailParameters(e)}
                  placeholder={placeholder}
                  type="text"
                />
              )}
            </FormattedMessage>
            <div className="buttonWrapper">
              <Link to="/">
                <button type="cancel" className="cancel">
                  <FormattedMessage id="contact.emailCancel" />
                </button>
              </Link>
              <a href={href} className="">
                <button type="submit" className="submit">
                  <FormattedMessage id="contact.emailSend" />
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    );

    const Overlay = (
      <div className="background">
        <Helmet
          title={this.props.intl.formatMessage({ id: "contact.title" })}
        />
        <div className="box">
          <h2>
            <FormattedMessage id="contact.consentHeading" />
          </h2>
          <hr className="contactSeperator" />
          <p className="info">
            <FormattedMessage id="contact.consentQuestion" />
          </p>
          <div className="buttonWrapper">
            <button onClick={() => this.cancel()} className="cancel">
              <FormattedMessage id="contact.consentNo" />
            </button>
            <button onClick={() => this.submit()} className="submit">
              <FormattedMessage id="contact.consentYes" />
            </button>
          </div>
        </div>
      </div>
    );

    switch (permission) {
      case true:
        return Main;
      case false:
        return emailOverlay;
      default:
        return Overlay;
    }
  }
}

const IFrame = (props) => {
  let path = "";
  if (props.locale === "de-DE") {
    path =
      "https://pipedrivewebforms.com/form/45cec450de63d7ffe6f115ed3d4e15d92084847";
  } else {
    path =
      "https://pipedrivewebforms.com/form/9197239ecb801351646348c3df7177442084847";
  }

  return <iframe scrolling="no" className="contactFrame" src={path} />;
};

export default injectIntl(Contact);
